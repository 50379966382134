import { HttpInterceptorFn, HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { tap, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { MessageService } from "primeng/api";

export const TokenInterceptorService: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const token = sessionStorage.getItem('token');
  const modifiedReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`),
  });

  return next(modifiedReq).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 460) {
          throwError(() => err.error);
          return;
        }

        if (err.status !== 401) {
         return;
        }
        authService.logout();
        router.navigate(['login']);
      }
    }));
}