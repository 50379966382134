import { Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
    {path: '', loadComponent: () => import('./pages/home-page/home-page.component').then(m => m.HomePageComponent), title: 'Accueil', canActivate: [AuthGuard]},
    {path: 'home', loadComponent: () => import('./pages/home-page/home-page.component').then(m => m.HomePageComponent), title: 'Accueil', canActivate: [AuthGuard]},
    {path: 'login', loadComponent: () => import('./pages/login-page/login-page.component').then(m => m.LoginPageComponent), title: 'Connexion'},
    {path: 'site/:id', loadComponent: () => import('./pages/site-page/site-page.component').then(m => m.SitePageComponent), title: 'Site', canActivate: [AuthGuard]},
    {path: 'admin', loadComponent: () => import('./pages/admin-page/admin-page.component').then(m => m.AdminPageComponent), title: 'Administration', canActivate: [AdminGuard]},
    {path: 'password-reset/:token', loadComponent: () => import('./pages/login-page/login-page.component').then(m => m.LoginPageComponent), title: 'Mot de passe oublié'},
];
