import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LinkComponent } from './components/link/link.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from "primeng/api";
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, LinkComponent, ToastModule],
  providers: [MessageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'pyrescom';

  constructor(
    public authService: AuthService
  ) { }
}
