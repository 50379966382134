import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { MessageService } from "primeng/api";
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    MessageService,
    provideHttpClient(
      withInterceptors([TokenInterceptorService]),
    )
  ]
};
