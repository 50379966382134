import { inject } from "@angular/core";
import { Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

export const AdminGuard = (state: RouterStateSnapshot) => {
    const auth = inject(AuthService);
    const router = inject(Router);

    // check if user is connected
    auth.checkUser();

    // if user is an admin
    if (auth.isAdmin()) {
      const stateUrl:any = state;
      let redirectUrl:any = stateUrl.url[0]['path'];
      if (stateUrl.params?.id !== undefined) {
        redirectUrl += '/' + stateUrl.params.id;
      }
      // set the redirectUrl incase of page reload
      sessionStorage.setItem('redirectUrl', '/' + redirectUrl);
      return true;
    }

    // if not logged in as admin then navigate to /login
    router.navigateByUrl('/login');
    return false;
}